<template>
  <div class="education-center-banner-slot__wrapper">
    <a-visibility show :on="[$breakpoint.mobile]">
      <i class="top-delimiter" />
    </a-visibility>
    <div class="education-center-banner-slot__banner-wrapper">
      <a-banner
        :banner-settings="$options.consts.BANNER_SETTINGS.HOMEPAGE.MPU_BANNER"
      />
    </div>
    <a-visibility show :on="[$breakpoint.mobile]">
      <i class="bottom-delimiter" />
    </a-visibility>
  </div>
</template>

<script>
import ABanner from 'shared/ABanner'
import { BANNER_SETTINGS } from 'enums/banners/banner-settings'

export default {
  name: 'BannerSlot',
  components: { ABanner },
  consts: { BANNER_SETTINGS }
}
</script>

<style scoped lang="scss">
@import '~assets/scss/local/mixins/homepage-latest-news';
@import '~assets/scss/global/mixins/figures';

.education-center-banner-slot__wrapper {
  position: relative;

  .education-center-banner-slot__banner-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    opacity: 0.5;

    @include mobile {
      position: relative;
      top: auto;
      left: auto;
      transform: unset;
      height: auto;
    }
  }

  .top-delimiter {
    @include delimiter($mb: 15px);
  }

  .bottom-delimiter {
    @include delimiter($mt: 15px);
  }
}
</style>
